<script setup lang="ts">
import type { LocationQuery } from 'vue-router'
import type { InputSearchQuery } from '~/types/search/types'

const props = withDefaults(
  defineProps<{
    collections?: boolean
    exclus?: boolean
  }>(),
  {
    collections: false,
    exclus: false,
  },
)
const { isMobile } = useDevice()
const localePath = useLocalePath()
const router = useRouter()
const { trackEvent } = useTracking()

const query = ref({} as InputSearchQuery)

const imageCover = computed(() =>
  isMobile
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/cover-winter-2024-mobile.webp'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/cover-winter-2024-desktop.webp',
)
const s3Url = computed(() =>
  isMobile
    ? 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/video-winter-2024-mobile.mp4'
    : 'https://collectionist.s3.eu-west-1.amazonaws.com/production/assets/homepage/2024/video-winter-2024-desktop.mp4',
)

const arrowScrollTo = computed(() => {
  let result = 'selection'

  if (props.collections) result = 'collections'
  if (props.exclus) result = 'exclus'

  return result
})

const goToSearch = (
  selectedOption: { type: string; label: string; value: string; slug: string },
  searchQuery: LocationQuery,
) => {
  if (selectedOption.type === 'location') {
    trackEvent({
      category: 'Homepage',
      event: 'search_started',
      source: 'homepage',
      suggestion: 'location',
      search_query: searchQuery,
      is_autocomplete: Boolean(searchQuery),
    })

    const path = localePath({
      name: 'search',
      query: { destinationId: selectedOption.value },
    })

    router.push(path)
  } else if (selectedOption.type === 'type-of-house') {
    trackEvent({
      category: 'Homepage',
      event: 'search_started',
      source: 'homepage',
      suggestion: 'house',
      search_query: searchQuery,
      is_autocomplete: Boolean(searchQuery),
    })

    const path = localePath({
      name: 'house-slug',
      params: { slug: selectedOption.slug },
    })

    router.push(path)
  }
}

const trackWhereToLeave = () => {
  trackEvent({
    category: 'General',
    event: 'typeform_homepage_click',
  })
}
</script>

<template>
  <base-video-bg :img="imageCover" :source="s3Url" class="home-bg-video">
    <div class="home-bg-video__overlay" />
    <base-container>
      <base-row class="justify-center">
        <base-col md="20" lg="14">
          <h2
            class="home-bg-video__title text-pre-line"
            data-cy="homepage-title"
          >
            {{ $t('home.headline') }}
          </h2>
          <p class="home-bg-video__subtitle" data-cy="homepage-subtitle">
            {{ $t('home.headline_subtitle') }}
          </p>

          <SearchInput
            :has-clear-button="false"
            :has-icon="false"
            :has-search-button="true"
            :model-value="query"
            class="search__input"
            location="homepage"
            @refine-by-query="goToSearch"
          />

          <base-button
            :to="localePath({ name: 'typeform' })"
            target="_blank"
            color="primary-light"
            variant="link"
            class="mt-1"
            @mousedown="trackWhereToLeave"
          >
            {{ $t('home.where_to') }}
          </base-button>
        </base-col>
      </base-row>
    </base-container>

    <base-arrow-scroll
      class="home-bg-video__arrow-scroll"
      @click="useScrollTo({ id: arrowScrollTo })"
    />
  </base-video-bg>
</template>

<style>
.home-bg-video {
  @apply h-screen	flex items-center text-center;
  min-height: 568px;
}

.home-bg-video__overlay {
  @apply bg-gray-700 bg-opacity-40 w-full h-full absolute;
}

.home-bg-video__title {
  margin: 0 0 1rem 0;
  @apply text-7xl;
}

@screen md {
  .home-bg-video__title {
    @apply text-8xl;
  }
}

.home-bg-video__title,
.home-bg-video__subtitle {
  @apply text-white;
}

.home-bg-video__subtitle {
  margin: 0 0 1.5rem 0;
}

.home-bg-video__arrow-scroll {
  @apply hidden absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

@screen sm {
  .home-bg-video__arrow-scroll {
    @apply block;
  }
}

.homepage .search-input .multiselect-label-span {
  @apply text-left pl-4;
}
</style>
