<script setup>
import { getImgSrc, getOpenGraph, getTwitter } from '~/helpers/useMetaData'

defineI18nRoute({
  paths: {
    en: '/',
    fr: '/',
  },
})
definePageMeta({
  gtmAdditionalEventData: { page_type: 'home' },
})

const config = useRuntimeConfig()
const localePath = useLocalePath()
const nuxtApp = useNuxtApp()
const { isSmallScreen } = useBreakpoint()
const { t, locale } = useI18n()
const { setCustomPageTheme } = useHeaderNavigation()
const { facebookDomainVerification } = config.public

const metaTitle = t('home.meta_title')
const metaDescription = t('home.meta_description')

const { data: homepageData } = await useFetch(`/api/${locale.value}/home`)
const {
  bigHouse,
  excluHouses,
  experiences,
  orderedFeaturedHouses,
  popularDestinations,
  selectionHouse,
} = homepageData.value

const selectionHouseBigImage = computed(() => {
  if (selectionHouse) {
    return selectionHouse
  }

  return ''
})
const filteredPopularDestinations = computed(() => {
  return popularDestinations.filter((desti) => desti.coverPhotoUrl)
})
const showExclus = computed(() => {
  return Boolean(excluHouses?.length)
})
const showFeaturedHouses = computed(() => {
  return (
    orderedFeaturedHouses?.filter((x) => x.houses?.length >= 1)?.length === 4
  )
})
const showSelectionHouse = computed(() => {
  return Boolean(selectionHouse)
})
const showBigHouse = computed(() => {
  if (bigHouse) {
    return Boolean(Object.keys(bigHouse).length)
  }

  return false
})

const showExperiences = computed(() => {
  return experiences?.length === 4
})
const showDestinations = computed(() => {
  return Boolean(popularDestinations?.length)
})

nuxtApp.runWithContext(() => {
  useHead({
    title: metaTitle,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: metaDescription,
      },
      {
        hid: 'facebook-domain-verification',
        name: 'facebook-domain-verification',
        content: facebookDomainVerification,
      },
      ...getOpenGraph(metaTitle, metaDescription, selectionHouse?.img),
      ...getTwitter(metaTitle, metaDescription, selectionHouse?.img),
    ],
    link: [...getImgSrc()],
  })
})

onBeforeMount(() => {
  setCustomPageTheme('transparent')
})
</script>

<template>
  <div class="index__container">
    <HomeHeading
      :collections="showFeaturedHouses"
      :exclus="showExclus"
      class="mt-header mx-0"
    />

    <general-featured-houses-block
      v-if="showFeaturedHouses"
      id="collections"
      :collections="orderedFeaturedHouses"
      :title-on-top="false"
      :search-button-visible="true"
      :title="$t('home.featured_houses.title')"
      property-type="all"
      page="Homepage"
      class="index__section !md:pt-[7.5rem] !pt-20"
    />

    <GeneralHotdealsBlock />

    <BaseSectionContent
      v-if="showExclus"
      id="exclus"
      :title="$t('home.iconicCollection.title')"
      :subtitle="$t('home.iconicCollection.text')"
      class="index__section !md:pt-[7.5rem] !pt-20"
    >
      <template #content>
        <GeneralExcluBlock :exclus="excluHouses" />
      </template>
    </BaseSectionContent>

    <BaseSectionContent
      v-if="showSelectionHouse"
      id="selection"
      :title="$t('global.selection.title')"
      :subtitle="$t('global.selection.description')"
      class="index__section"
    >
      <template #header>
        <div
          :data-aos-once="true"
          data-aos="fade"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <BaseButton
            :to="localePath('search')"
            class="text-lg"
            color="secondary"
            variant="link"
          >
            {{ $t('global.selection.collection') }}
          </BaseButton>
        </div>
      </template>
      <template #content>
        <GeneralSelectionBlock
          :big-image="selectionHouseBigImage"
          page="Homepage"
        />
      </template>
    </BaseSectionContent>

    <BaseSectionContent
      :title="$t('global.services.title')"
      :subtitle="$t('global.services.description')"
      class="index__section index__section--services"
    >
      <template #header>
        <div
          :data-aos-once="true"
          data-aos="fade"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <BaseSlidingInput
            id-form="freedom"
            :inline="!isSmallScreen"
            class="index__sliding-input"
            section="freedom"
          />
        </div>
      </template>
      <template #content>
        <GeneralServicesBlock />
      </template>
    </BaseSectionContent>

    <HomeBigImg v-if="showBigHouse" :house="bigHouse" class="index__section" />

    <BaseSectionContent
      v-if="showExperiences"
      :title="$t('home.experiences.title')"
      :subtitle="$t('home.experiences.description')"
      class="index__section"
    >
      <template #header>
        <div :data-aos-once="true" data-aos="fade" data-aos-delay="300">
          <BaseButton
            :href="$t('home.experiences.url')"
            target="_blank"
            rel="noopener"
            class="text-lg"
            color="secondary"
            variant="link"
          >
            {{ $t('home.experiences.link') }}
          </BaseButton>
        </div>
      </template>
      <template #content>
        <home-experiences :experiences="experiences" />
      </template>
    </BaseSectionContent>

    <BaseSectionContent
      v-if="showDestinations"
      :title="$t('global.destinations.title')"
      class="index__section index__section--destinations"
    >
      <template #content>
        <GeneralDestinationsBlockCollapse
          :destinations="filteredPopularDestinations"
          page="Homepage"
        />
      </template>
    </BaseSectionContent>

    <home-owner class="index__section index__section--owner" />

    <general-medias-block />
    <BaseContainer>
      <BaseRow>
        <BaseCol>
          <h1 class="index__title">
            {{ $t('home.home_title') }}
          </h1>
        </BaseCol>
      </BaseRow>
    </BaseContainer>

    <GeneralValuesBlock />
  </div>
</template>

<style>
.index__container {
  @apply w-full;
}

.index__section {
  margin: 0 0 5rem 0;
}

@screen md {
  .index__section {
    margin: 0 0 7.5rem 0;
  }
}

@screen lg {
  .index__section {
    margin: 0 0 10rem 0;
  }
}

@screen lg {
  .index__section--services {
    margin: 0 0 7.5rem 0;
  }
}

.index__sliding-input {
  @apply mb-7;
}

@screen md {
  .index__sliding-input {
    @apply mb-16;
  }
}

.index__section--owner {
  margin: 0 0 2rem 0;
}

@screen md {
  .index__section--owner {
    margin: 0 0 5rem 0;
  }
}

@screen lg {
  .index__section--owner {
    margin: 0 0 3rem 0;
  }
}

.index__link--more {
  @apply p-0;
}

.index__link--more:hover,
.index__link--more:focus {
  @apply no-underline;
}

.index__title {
  margin: 2rem 0 3.5rem 0;
  @apply text-5xl text-center;
}
</style>
